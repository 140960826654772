.property-details {
    .details-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 30px 0px;
        width: 100%;
        background-color: rgba($color: #CD5828, $alpha: 0.1);
        padding: 10px 15px;
        border-radius: 5px;

        .address {
            display: flex;
            align-items: center;

            .back-btn {
                margin-right: 15px;
                border-radius: 50%;
                padding: 8px 10px;
                background-color: transparent;
                border: 0px solid transparent;
                display: flex;
            }

            .back-btn:hover {
                background-color: rgba($color: #CD5828, $alpha: 0.1);
            }

            h5 {
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 0px;
                color: #323232;
                line-height: 28px;
            }

            p {
                font-size: 15px;
                margin-bottom: 0px;
                color: #323232;
                font-weight: 400;
            }
        }

        .features {
            display: flex;
            align-items: center;

            .tile:first-child {
                border-left: 0px solid transparent;
            }

            .tile {
                text-align: center;
                border-left: 2px solid rgba($color: #CD5828, $alpha: 0.3);
                padding: 0px 25px;

                .value {
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 0px;
                    color: #323232;
                }

                .label {
                    font-size: 14px;
                    margin-bottom: 0px;
                    color: #323232;
                    font-weight: 400;
                }
            }
        }
    }

    .page-left {
        .swiper {
            .swiper-button-next {
                height: 30px !important;
                width: 30px !important;
                top: 55%;
            }
    
            .swiper-button-prev {
                height: 30px !important;
                width: 30px !important;
                top: 55%;
            }
    
    
            .swiper-button-next:after,
            .swiper-button-prev:after {
                background-color: #FFF;
                font-size: 12px !important;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
                width: 30px;
                font-weight: 700;
                color: #000;
            }
        }

        .property-image-skeleton {
            width: 100%;
            height: 600px;
        }

        .property-photos {
            width: 100%;
    
            .img-slider {
                img {
                    width: 100%;
                    height: 600px;
                    object-fit: cover;
                    border-radius: 5px;
                }
            }
        }

        .description {
            margin-top: 20px;

            .content-css {
                font-size: 15px;
                font-weight: 500;
                color: #323232;

                span {
                    .show-more-less-clickable {
                        display: block;
                        margin-top: 10px;
                        color: blue;
                        text-decoration: none;
                        font-weight: 600;
                    }
                }
            }
        }

        .listing-agent-details {
            margin-top: 20px;

            p {
                margin-bottom: 0px;
                font-size: 15px;
                font-weight: 600;

                span {
                    font-weight: 700;
                }
            }
        }

        .property-details {
            margin: 30px 0px;

            h4 {
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 0px;
            }

            .location-map {
                width: 100%;
                height: 350px;
                margin-top: 15px;
                border-radius: 5px;

                iframe {
                    width: 100%;
                    height: 350px;
                    background: 5px;
                }
            }
        }

        .property-indetail {
            margin-bottom: 30px;

            h2 {
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 0px;
            }

            .left-section {
                background-color: rgba($color: #CD5828, $alpha: 0.1);
                border-radius: 5px;
                margin-top: 15px;
                border: 2px solid #E5E5E5;

                .details-box {
                    background-color: rgba($color: #CD5828, $alpha: 0.1);
                    padding: 10px 20px;
            
                    .label {
                        font-size: 14px;
                        font-weight: 500;
                        color: #323232;
                        margin-bottom: 0px;
                    }
            
                    .value {
                        overflow-wrap: anywhere;
                        font-size: 14px;
                        font-weight: 700;
                        color: #323232;
                        margin-bottom: 0px;
                    }
                }

                .details-box.white {
                    background-color: #FFF;
                }

                .details-box:last-child {
                    margin-bottom: 0px;
                }
            }
        }

        .mortgage-calculator {
            margin-bottom: 10px;

            h2 {
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 0px;
            }

            .calculate-btn {
                width: 100%;
                background-color: #CD5828;
                border-color: #CD5828;
                font-size: 14px;
                font-weight: 600;
                color: #FFF;
            }

            .monthly-payment-box {
                margin-top: 15px;
                border: 1px solid #E5E5E5;
                border-radius: 5px;
                text-align: center;
                padding: 10px ;

                span {
                    display: block;
                    font-weight: 600;
                    font-size: 13px;
                    margin-bottom: 0px;
                }

                h2 {
                    margin-top: 5px;
                    font-weight: 700;
                    margin-bottom: 0px;
                }

                .total-cost {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    background-color: #F5F5F5;
                    border-radius: 5px;
                    padding: 10px 15px;
                    margin-top: 10px;

                    p {
                        margin-bottom: 0px;
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }

            .reset-btn {
                width: 100%;
                background-color: #F5F5F5;
                font-size: 14px;
                font-weight: 600;
                color: #323232;
                border: 2px solid #E5E5E5;
                padding: 5px 20px;
                transition: background-color 0.3s ease-in-out;
                margin-left: 10px;
            }

            .reset-btn:hover {
                background-color: #E5E5E5;
            }

            .monthly-tax-box {
                margin-top: 15px;
                border: 1px solid #E5E5E5;
                border-radius: 5px;
                text-align: center;
                padding: 10px;

                span {
                    display: block;
                    font-weight: 600;
                    font-size: 12px;
                    margin-bottom: 0px;
                }

                h4 {
                    margin-top: 5px;
                    font-weight: 700;
                    margin-bottom: 0px;
                }
            }
        }

        .nearby-schools {
            h4 {
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 0px;
            }

            .schools-table {
                width: 100%;
                margin-top: 15px;

                th {
                    background-color: rgba($color: #CD5828, $alpha: 0.1);
                    padding: 5px 20px;
                    font-weight: 500;
                    font-size: 14px;
                    border: 1px solid #E5E5E5;
                }

                td {
                    font-weight: 500;
                    font-size: 14px;
                    padding: 6px 20px;
                    border: 1px solid #E5E5E5;
                }
            }
        }
    }

    .page-right {
        position: sticky;
        top: 0;

        .listed-by {
            background-color: rgba($color: #CD5828, $alpha: 0.1);
            border-radius: 5px;
            padding: 10px 15px;
            margin-bottom: 10px;

            p {
                font-size: 15px;
                font-weight: 600;
                color: #323232;
                margin-bottom: 5px;
            }
        }

        .official-details {
            background-color: rgba($color: #CD5828, $alpha: 0.1);
            border-radius: 5px;
            padding: 10px 15px;

            .last-updated {
                font-size: 12px;
                color: grey;
                font-weight: 600;
                margin-bottom: 0px;
            }

            .details-box {
                margin-top: 10px;

                .label {
                    font-size: 15px;
                    font-weight: 500;
                    color: #323232;
                    margin-bottom: 0px;
                }

                .value {
                    overflow-wrap: anywhere;
                    font-size: 15px;
                    font-weight: 700;
                    color: #323232;
                    margin-bottom: 0px;
                }
            }
        }

        .contact-compass-agent {
            margin-top: 25px;

            h2 {
                font-weight: 700;
                font-size: 16px;
                margin-bottom: 0px;
                color: #323232;
            }

            .contact-form {
                margin-top: 10px;
                border: 2px solid #E5E5E5;
                border-radius: 5px;
                padding: 15px;

                p {
                    font-size: 14px;
                    font-weight: 600;
                    color: #323232;
                    margin-bottom: 5px;

                    a {
                        text-decoration: none;
                    }
                }

                span {
                    font-size: 12px;
                    font-weight: 400;
                    display: block;
                    margin-top: 10px;
                    line-height: 18px;
                }
            }

            .submit-btn {
                width: 100%;
                background-color: #CD5828;
                border-radius: 5px;
                font-size: 14px;
                font-weight: 600;
                border-color: #CD5828;
            }
        }
    }

    .similar-homes {
        padding: 60px 0px 20px 0px;

        h2 {
            font-weight: 800;
        }
    }

    .conditions-text {
        padding-bottom: 40px;
        
        p {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 10px;
            text-align: justify;
        }
    }
}